import React, { FC } from "react";
import styles from "./navbar.module.scss";
import Link from "next-translate-routes/link";
import { Container, Flex } from "../basic";
import { Logo } from "../images";
import { UsernameLogin } from "../username-login";
import { Pages } from "../../lib/pages";

const Navbar: FC = () => (
	<div className={styles.navbar}>
		<Container>
			<Flex>
				<Link href={Pages.Index} passHref>
					<div className={styles.logo}>
						<Logo />
					</div>
				</Link>
				<UsernameLogin />
			</Flex>
		</Container>
	</div>
);

export default Navbar;
