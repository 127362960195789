import { Link } from "next-translate-routes";
import { useRouter } from "next/router";
import React, { FC } from "react";
import { MdLanguage } from "react-icons/md";
import styles from "./language-selector.module.scss";

const LanguageSelector: FC = () => {
	const router = useRouter();

	const languageText = (lang: string) => {
		switch (lang) {
			case "en":
				return "English";
			case "cs":
				return "Čeština";
			case "de":
				return "Deutsch";
			default:
				return "Unknown";
		}
	};

	return (
		<div className={styles.languages}>
			<MdLanguage size={32} className={styles.languageIcon} />
			<div className={styles.dropdown}>
				{router.locales.map((locale) => (
					<div key={locale} className={styles.linkDiv}>
						<Link href={router.pathname} locale={locale} passHref>
							<a
								className={router.locale === locale ? styles.linkCurrent : styles.link}>
								{languageText(locale)}
							</a>
						</Link>
					</div>
				))}
			</div>
		</div>
	);
};

export default LanguageSelector;
