import React, { FC } from "react";
import Image from "next/image";
import image from "./codeventure-logo.png";

const Logo: FC = () => (
	<Image
		src={image}
		layout="responsive"
		width={960}
		height={211}
		sizes="25vw"
		alt="Codeventure Logo"
		priority
	/>
);

export default Logo;
