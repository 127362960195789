import { AppProps } from "next/app";
import { ApolloProvider } from "@apollo/client";
import { useApollo } from "../lib/apollo";
import { Toaster } from "react-hot-toast";
import ProgressBar from "nextjs-progressbar";
import { Pages } from "../lib/pages";
import Layout from "../components/layout";
import "../styles/global.scss";
import { useMemo } from "react";
import { withTranslateRoutes } from "next-translate-routes";
import { NextIntlProvider } from "next-intl";

import * as sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

sentry.init({
	dsn: process.env.NEXT_PUBLIC_SENTRY_URL,
	integrations: [new BrowserTracing()],
	tracesSampleRate: 1.0,
});

const App = ({ Component, pageProps, router }: AppProps) => {	
	const apolloClient = useApollo(router.locale);
	const title = useMemo(() => {
		switch (router.pathname) {
			case Pages.Index:
				return null;
			case Pages.About:
				return "O projektu";
			case Pages.Login:
				return "Přihlášení";
			case Pages.Logout:
				return "Odhlášování";
			case Pages.PrivacyPolicy:
				return "Ochrana osobních údajů";
			case Pages.Profile:
				return "Profil";
			case Pages.Register:
				return "Registrace";
			case Pages.TermAndConditions:
				return "Podmínky používání";
			case Pages.ResetPassword:
				return "Resetování hesla";
			case Pages.Cards:
				return "Seznam kartiček";
			case Pages.Groups:
				return "Skupiny";
			default:
				return null;
		}
	}, [router.pathname]);

	if (router.pathname === Pages.Game)
		return (
			<ApolloProvider client={apolloClient}>
				<NextIntlProvider messages={pageProps.messages}>
					<Component {...pageProps} />
				</NextIntlProvider>
			</ApolloProvider>
		);

	return (
		<ApolloProvider client={apolloClient}>
			<NextIntlProvider messages={pageProps.messages}>
				<ProgressBar
					color="#ffffff"
					height={2}
					options={{
						showSpinner: false,
					}}
				/>
				<Layout title={title}>
					<Component {...pageProps} />
				</Layout>
				<Toaster />
			</NextIntlProvider>
		</ApolloProvider>
	);
};

export default withTranslateRoutes(JSON.parse(`{"defaultLocale":"cs","locales":["cs","en","de"],"routesTree":{"name":"","paths":{"default":""},"children":[{"name":"about","paths":{"default":"about","cs":"o-projektu","de":"ber-das-projekt"}},{"name":"cards","paths":{"default":"cards","cs":"karticky","de":"karten"}},{"name":"game","paths":{"default":"game","cs":"hra","de":"spiel"}},{"name":"groups","paths":{"default":"groups","cs":"skupiny","de":"gruppen"}},{"name":"index","paths":{"default":"index"}},{"name":"loading","paths":{"default":"loading"}},{"name":"login","paths":{"default":"login","cs":"prihlaseni","de":"login"}},{"name":"logout","paths":{"default":"logout","cs":"odhlaseni","de":"abmeldung"}},{"name":"password-reset","paths":{"default":"password-reset","cs":"reset-hesla","de":"passwort-zurucksetzen"}},{"name":"privacy-policy","paths":{"default":"privacy-policy","cs":"ochrana-osobnich-udaju","de":"datenschutz"}},{"name":"profile","paths":{"default":"profile","cs":"profil","de":"profil"}},{"name":"register","paths":{"default":"register","cs":"registrace","de":"registrieren"}},{"name":"send-email","paths":{"default":"send-email","cs":"odeslat-email","de":"email-senden"}},{"name":"terms-and-conditions","paths":{"default":"terms-and-conditions","cs":"podminky-pouzivani","de":"nutzungsbedingungen"}}]}}`), App);
