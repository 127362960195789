export enum Pages {
	Index = "/",
	About = "/about",
	Game = "/game",
	Login = "/login",
	Logout = "/logout",
	PrivacyPolicy = "/privacy-policy",
	Profile = "/profile",
	Register = "/register",
	TermAndConditions = "/terms-and-conditions",
	ResetPassword = "/password-reset",
	SendEmail = "/send-email",
	Cards = "/cards",
	Groups = "/groups",
}
