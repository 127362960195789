import { IncomingMessage, ServerResponse } from "http";
import { useMemo } from "react";
import {
	ApolloClient,
	InMemoryCache,
	NormalizedCacheObject,
} from "@apollo/client";
import { GetAuth } from "./auth";

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;
let lastLocale = "";

const address = process.env.NEXT_PUBLIC_GRAPHQL_URI;
if (!address) throw new Error("No GRAPHQL_URI specified");
const ssl = process.env.NEXT_PUBLIC_GRAPHQL_SSL === "true";

export type ResolverContext = {
	req?: IncomingMessage;
	res?: ServerResponse;
};

const createIsomorphLink = (locale: string) => {
	if (typeof window === "undefined") {
		const { SchemaLink } = require("@apollo/client/link/schema");
		const { schema } = require("./graphql/schema");
		return new SchemaLink({ schema, context: {} });
	} else {
		const { HttpLink, split } = require("@apollo/client");
		const { GraphQLWsLink } = require("@apollo/client/link/subscriptions");
		const { createClient } = require("graphql-ws");
		const { getMainDefinition } = require("@apollo/client/utilities");
		
		const httpLink = new HttpLink({
			uri: `${ssl ? "https" : "http"}://${address}/graphql`,
			headers: {
				authorization: GetAuth(),
				language: locale,
			},
		});
		const wsLink = new GraphQLWsLink(
			createClient({
				url: `${ssl ? "wss" : "ws"}://${address}/graphql`,
				options: {
					reconnect: true,
					connectionParams: {
						authorization: GetAuth(),
						language: locale,
					},
				},
			})
		);

		const splitLink = split(
			//@ts-ignore
			({ query }) => {
				const definition = getMainDefinition(query);
				return (
					definition.kind === "OperationDefinition" &&
					definition.operation === "subscription"
				);
			},
			wsLink,
			httpLink
		);
		return splitLink;
	}
};

const createApolloClient = (locale: string) => {	
	return new ApolloClient({
		ssrMode: typeof window === "undefined",
		link: createIsomorphLink(locale),
		cache: new InMemoryCache(),
	});
};

export const initializeApollo = (
	locale: string = "cs",
) => {
	let _apolloClient = apolloClient ?? createApolloClient(locale);

	if (lastLocale !== locale) {
		_apolloClient = createApolloClient(locale);
		lastLocale = locale;
	}

	// For SSG and SSR always create a new Apollo Client
	if (typeof window === "undefined") return _apolloClient;
	// Create the Apollo Client once in the client
	if (!apolloClient) apolloClient = _apolloClient;

	return _apolloClient;
};

export const useApollo = (locale: string) => {
	const store = useMemo(() => initializeApollo(locale), [locale]);
	return store;
};
